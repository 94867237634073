<template>
  <div class="vx-row mb-4">
    <div class="vx-col sm:w-1/5 w-full">
      <span>{{options.name}}</span>
    </div>
    <div v-if="options.max && options.min" class="vx-col sm:w-4/5 w-full">
      <vs-input  
        class="w-full" 
        :value="value"
        :name="options.name"
        type="number"
        :max="options.max"
        v-validate="'between:' + options.min + ',' + options.max"
        :min="options.min"
        @input="updateValue" />
      <span class="text-danger text-sm">{{ errors.first(options.name) ? `${options.name} must be bet. ${options.min} and ${options.max}` : '' }}</span>
    </div>
     <div v-else class="vx-col sm:w-4/5 w-full">
      <vs-input  class="w-full" :value="value" min="0" :name="options.name" type="number"  @input="updateValue" />
    </div>

  </div>
</template>

<script>
export default {
  props: {
    options: { required: true, type: Object },
    value: { required: true }
  },
  data () {
    return {
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {

  },
  mounted() {
  },
  
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    },
  }
}
</script>

<style>

</style>