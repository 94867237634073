<template>
  <div class="vx-row mb-4">
    <div class="vx-col sm:w-1/5 w-full">
      <span>{{options.name}}</span>
    </div>
    <div class="vx-col sm:w-4/5 w-full">
      <vs-switch v-if="options.isToggle" class="" :value="value" @input="updateValue"></vs-switch>
      <vs-checkbox v-else class="" :value="value" @input="updateValue"></vs-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    applicationId: { required: true },
    options: { required: true, type: Object },
    value: { required: true },
  },
  data () {
    return {
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  mounted() {
  },
  
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    },
  }
}
</script>

<style>

</style>