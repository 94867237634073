<template>

  <travio-center-container grid-width='1/2'>
    <h2 style="color:#636363">RSS Link Builder</h2>
    <p v-html="infoLink" class="mt-2 mb-4"></p>
    <vx-card>
      
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label>Output</label>
          <vs-input class="w-full mt-2" name="Output Link" v-model="rssOutputLink" />
        </div>
        <div class="vx-col w-full">
          <div class="flex flex-wrap justify-end">
            <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
            <vs-button 
              v-clipboard:copy="rssOutputLink"
              v-clipboard:success="onCopy" 
              icon-pack="feather" icon="icon-clipboard" class="mt-4"
              title="Copy To Clipboard"
            >Copy To Clipboard
            </vs-button>
          </div>
        </div>
      </div>

      <div v-for="(param, index) in linkParams" :key="index">
        <component 
          :is="param.component" 
          v-model="outputValue[param.key]"
          v-bind="{ options: param, applicationId: applicationId, allValues: outputValue }"
          @input="onUpdate"
        >
        </component>
      </div>

    </vx-card>
  </travio-center-container>
</template>

<script>

import RssSelect from "./components/RssSelect.vue"
import RssDateTime from "./components/RssDateTime.vue"
import RssInteger from "./components/RssInteger.vue"
import RssCheckbox from "./components/RssCheckbox.vue"


export default {
  components: {
    RssSelect,
    RssDateTime,
    RssInteger,
    RssCheckbox
  },
  props: {
    applicationId: { required: true },
  },
  data () {
    return {
      rssOutputLink: '',
      linkParams:[],
      outputValue: {
        format: 'rss',
        type: '0',
        maxOffers: '',
        lang: '',
        curr: '',
        budgetMin: '',
        budgetMax: '',
        orgs: '',
        dsts: '',
        brd: '',
        cls: '',
        fltcr: '',
        retOnly: true,
        exactDates: false,
        startDate: '',
        endDate: '',
        durMin: '',
        durMax: '',
        ratMin: '',
        sort: 'price:asc'
      },
      infoLink:'Build links to your RSS feed of travel offers for embedding in email newsletters and more. Click <a href="https://support.travelgenix.io/hc/en-gb/articles/4409163161873-RSS-Link-Builder" target="_blank">here</a> for support on this tool.'
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }
    this.$vs.loading()
    this.$http.get(`/api/applications/${this.applicationId}/regionalSettings`)
    .then(response => {
        const languages = response.data.languages.map(x => ({ code: x, label: x}));
        const currencies = response.data.currencies.map(x => ({ code: x, label: x}));

        this.linkParams =  [
          { 
            key:'format',
            name: 'Feed Format', 
            selectOptions: [{ code: 'rss', label: 'RSS format (recommended)'}, { code: 'atom', label: 'ATOM format'},],
            allowMultiple: false,
            component: 'RssSelect'
          }, 
          {
            key:'type',
            name: 'Type of offers', 
            selectOptions: [{ code: '0', label: 'Any'}, { code: '1', label: 'Flights'}, { code: '2', label: 'Accomodation'}, { code: '3', label: 'Packages (Flight + Hotel)'}],
            allowMultiple: false,
            component: 'RssSelect'
          },
          { 
            key:'maxOffers',
            name: 'Maximum offers to return', 
            min: 10,
            max: 100,
            component: 'RssInteger'
          }, 
          {
            key:'lang',
            name: 'Language', 
            selectOptions: languages,
            allowMultiple: false,
            component: 'RssSelect'
          },
          {
            key:'curr',
            name: 'Currency', 
            selectOptions: currencies,
            component: 'RssSelect'
          },
          {
            key:'pricingType',
            name: 'Pricing Type Match', 
            selectOptions: [{ code: '1', label: 'Total'}, { code: '2', label: 'Person'}, { code: '3', label: 'Night'}],
            component: 'RssSelect'
          },
          { 
            key:'budgetMin',
            name: 'Budget (Minimum)', 
            component: 'RssInteger'
          },
          { 
            key:'budgetMax',
            name: 'Budget (Maximum)', 
            component: 'RssInteger'
          },
          {
            key:'orgs',
            name: 'Origins', 
            allowMultiple: true,
            taggable: true,
            component: 'RssSelect'
          },
          {
            key:'dsts',
            name: 'Destinations', 
            allowMultiple: true,
            taggable: true,
            component: 'RssSelect'
          },
          {
            key:'brd',
            name: 'Board Basis', 
            selectOptions: [{ code: "1",  label: "Room Only"},{ code: "2",  label: "Self Catering"},{ code: "3",  label: "Bed and Breakfast"},{ code: "4",  label: "Dinner, Bed and Breakfast"},{ code: "5",  label: "Half Board"},{ code: "6",  label: "Full Board"},{ code: "7",  label: "All Inclusive"},{ code: "8",  label: "All Inclusive Plus"},{ code: "9",  label: "Catered Chalet"},{ code: "10",  label: "Assisted Chalet"},{ code: "11",  label: "Managed Chaled"},{ code: "12",  label: "Full Board Plus"},{ code: "13",  label: "Catered Lodge"},{ code: "14",  label: "Mixed"},{ code: "15",  label: "Bed and Dinner"},{ code: "16",  label: "Bed and Lunch"},{ code: "17",  label: "Bed and Meal"}],
            allowMultiple: true,
            component: 'RssSelect'
          },
          {
            key:'cls',
            name: 'Cabin Classes', 
            selectOptions: [ { code: '1', label: 'Economy'}, { code: '2', label: 'Premium Economy'},{ code: '3', label: 'Business'}, { code: '4', label: 'First'} ],
            allowMultiple: true,
            component: 'RssSelect'
          },
          {
            key:'fltcr',
            name: 'Flight Carriers', 
            allowMultiple: true,
            taggable: true,
            component: 'RssSelect'
          },
          {
            key:'retOnly',
            name: 'Return Flights Only',
            component: 'RssCheckbox'
          },
          {
            key:'exactDates',
            name: 'Exact Dates',
            component: 'RssCheckbox',
            isToggle: true
          },
          { 
            key:'startDate',
            name: 'Dates From', 
            component: 'RssDateTime',
            showIf: 'exactDates'
          }, 
          { 
            key:'endDate',
            name: 'Dates To', 
            component: 'RssDateTime',
            showIf: 'exactDates'
          },
          { 
            key:'durMin',
            name: 'Duration (Minimum)', 
            component: 'RssInteger'
          }, 
          { 
            key:'durMax',
            name: 'Duration (Maximum)', 
            component: 'RssInteger'
          }, 
          // Gap ratMin
          {
            key:'sort',
            name: 'Sort', 
            selectOptions: [{ code: 'price:asc', label: 'Price Ascending'}, { code: 'price:desc', label: 'Price Descending' }, { code: 'random', label: 'Random Ordering' }],
            component: 'RssSelect'
          },
        ]

        this.onUpdate()
    })
    .catch(error => console.error(error))
    .finally(() => this.$vs.loading.close())  

  },
  mounted() {
  },
  
  methods: {
    onSubmit (){

    },
    onUpdate (event) {
      if(!this.outputValue['format']){
        this.rssOutputLink = ''  
        return
      }
      this.resetDateFields()
      let rssLink = `https://api.travelify.io/widgetsvc/traveloffers/${this.outputValue['format']}/${this.applicationId}?`
      
      var params = Object.keys(this.outputValue).filter(x => x !== 'format')
      
      for (const param of params) {
        
        if(this.outputValue[param]){

          const field = this.linkParams.find(x => x.key === param)
          let val = '';
          if (field.allowMultiple) {
            val = this.outputValue[param]
              .map(x => encodeURIComponent(x))
              .join()
            
          } else {
            val = encodeURIComponent(this.outputValue[param])
          }
          
          if (val !== "0" && !val) continue // 0 is valid since selected dropdown can have a code value of 0

          rssLink = `${rssLink}&${param}=${val}`
        }
      }
      this.rssOutputLink = rssLink

    },
    onCopy () {
      this.$_notifySuccess('RSS link copied to clipboard')
    },
    handleCancel () {
      this.$router.push({
        name: "application-tools",
        params: { applicationId: this.applicationId },
      });
    },
    resetDateFields () {
      if ( !this.outputValue['exactDates'] ) {
        this.outputValue['startDate'] = ''
        this.outputValue['endDate'] = ''
      }
    }
   
  }
}
</script>

<style>

</style>