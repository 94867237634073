<template>
  <div class="vx-row mb-4">
    <div class="vx-col sm:w-1/5 w-full" style="align-content:flex-end">
      <span>{{options.name}}</span>
    </div>
    <div class="vx-col sm:w-4/5 w-full">
     <!-- Multi select dropdown -->
      <v-select v-if="options.allowMultiple && options.taggable"
        :value="selectedValue"
        placeholder="Please type value and press enter"
        @input="updateValue" 
        taggable
        multiple />

      <v-select v-else-if="options.allowMultiple"
        :value="selectedValue"
        @input="updateValue" 
        :options="options.selectOptions"
        multiple />

      <!-- Standard select dropdown -->
      <v-select v-else
        :value="selectedValue"
        @input="updateValue" 
        :options="options.selectOptions" />

    </div>
  </div>
</template>

<script>

export default {
  props: {
    applicationId: { required: true },
    options: { required: true, type: Object },
    value: { required: true }
  },
  data () {
    return {
      selectedValue: null
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  mounted() {
    if(this.options.allowMultiple) {
      this.selectedValue = this.$_.filter(this.options.selectOptions, x => this.$_.includes(this.value, x.code)) || []
    } else {
      this.selectedValue = this.$_.find(this.options.selectOptions, x => x.code == this.value) || {}
    }
  },
  
  methods: {
    updateValue (value) {
      this.selectedValue = value;
      if(this.options.allowMultiple && this.options.taggable) {
        // Taggable sets value as an array so no need to map
        this.$emit('input', value)
      } else if(this.options.allowMultiple) {
        // Value will be saved as an array
        this.$emit('input', this.$_.map(value, x => x.code))
      } else {
        this.$emit('input', value && value.code)
      }
    },
  }
}
</script>

<style>

</style>