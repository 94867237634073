<template>
  <div v-if="showIf" class="vx-row mb-4">
    <div class="vx-col sm:w-1/5 w-full" style="align-content:flex-end">
      <span>{{options.name}}</span>
    </div>
    <div class="vx-col sm:w-4/5">
      <div class="vx-row">
        <div class="vx-col w-5/6">
          <flat-pickr v-model="dateModel" :config="{ dateFormat: 'Y-m-d', altInput: true }" class="w-full"  @input="updateValue"/>
        </div>
        <div class="vx-col w-1/6 pl-0">
          <vs-button type="filled" color="danger" class="w-full" icon-pack="feather" icon="icon-x" title="Clear" @click="clearValue"></vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    flatPickr
  },
  props: {
    applicationId: { required: true },
    options: { required: true, type: Object },
    value: { required: true },
    allValues: { required: true },
  },
  data () {
    return {
      dateModel: '',
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    showIf () {
      return this.allValues[this.options.showIf]
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.dateModel = newVal
    }
  },
  created() {
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    },
    clearValue () {
      this.dateModel = ''
      this.$emit('input', '')
    },
  }
}
</script>

<style>

</style>